import React from 'react'

export const SeriesIntro = () => {
  return (
    <p>
      <em>
        This post is part of a series about Apollo Federation architectural
        patterns. Check out the{' '}
        <a href="../apollo-federation-patterns-1/">introduction</a> for some
        background on how the example code is structured.
      </em>
    </p>
  )
}
