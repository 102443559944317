import React from 'react'

const ClientSideOnlyLazy = React.lazy(() => import('./sandbox-iframe'))
export const Sandbox = ({ ...props }) => {
  const isSSR = typeof window === 'undefined'
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy {...props} />
        </React.Suspense>
      )}
    </>
  )
}
